import React from "react"
import PreHeading from "../PreHeading"

import BlockSvg from "../../images/block.inline.svg"

import Style from "./SoloToOrchestra.module.css"

import { DateOverlayButton } from "../DateOverlay/DateOverlayButton"

const SoloToOchestra: React.FC = () => {
    return <section className={`flex flex-wrap desktop:flex-no-wrap section-x-padding section-x-margin ${Style.bgOrchestra}`}>
        <div hidden className={`block w-full desktop:w-2/3 desktop:pr-20 ${Style.blurryBlocks}`}>
            <BlockSvg className="m-auto w-full max-w-md max-h-full desktop:pt-32 mobile:px-8" />
        </div>
        <div className="w-full desktop:w-1/3 desktop:my-auto">
            <PreHeading>
                Die Quadio-Strategie
            </PreHeading>
            <h2>Aus Solo wird Orchester</h2>
            <p>Der Erfolg von Quadio fußt auf einem Grundsatz: Menschen zu befähigen, ihr volles Potenzial zu nutzen und somit ganze Unternehmen nach vorne zu bringen. Unsere Produkte füllen daher die Lücken, die im Zuge von Digitalisierung und Globalisierung entstehen und verwandelt sie in Möglichkeiten: Bessere Unternehmenskultur, gestärkter Vertrieb und effektiv geschulte Mitarbeiter. Ob nun ein Baustein oder alle: Mit unseren neurowissenschaftlich getesteten Methoden erhalten Sie und Ihr Unternehmen den Schub, den Sie sich wünschen.</p>
            <DateOverlayButton appearance="secondary">Termin vereinbaren</DateOverlayButton>
        </div>
    </section>
}

export const SoloToOchestraEn: React.FC = () => {
    return <section className={`flex flex-wrap desktop:flex-no-wrap section-x-padding section-x-margin ${Style.bgOrchestra}`}>
        <div hidden className={`block desktop:w-2/3 sm_desktop:w-1/2 tablet:w-full desktop:pr-20 ${Style.blurryBlocks}`}>
            <BlockSvg className="m-auto w-full max-w-md max-h-full desktop:pt-32 mobile:px-8" />
        </div>
        <div className="desktop:w-1/3 sm_desktop:w-1/2 tablet:w-full desktop:my-auto">
            <PreHeading>
                The Quadio Formula
            </PreHeading>
            <h2>From Solo to Symphony</h2>
            <p>
                The success of Quadio is rooted in one axiom: empowering people to utilize and  maximize their human capital – an act that stimulates the entire organization towards growth. Hence, our products are meticulously crafted not only to fill the void which emerge in the course of globalization and digitalization but also to convert those gaps into prospects: Strong corporate culture, reinforced sales and effectively trained employees. Be it one single component or as a whole, with our neuroscientifically tested methods we offer the drive and thrust that is needed for your organization.
            </p>
            <DateOverlayButton appearance="secondary">Make an Inquiry</DateOverlayButton>
        </div>
    </section>
}

export default SoloToOchestra
