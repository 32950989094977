import React from "react"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Style from "./Awards.module.css"

const Awards: React.FC = () => {
    const {
        awardsGermanInno,
        awardsMittelstand,
        awardsChange,
        awardsIndustriepreis,
    } = useStaticQuery(graphql`        
        fragment AwardImage on File {
            childImageSharp {
                fixed(width: 128, height: 128, cropFocus: CENTER, fit: CONTAIN) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        query AwardsImages {
            awardsGermanInno: file(relativePath: { eq: "pages/index/awards-2018-german-innovation.png" }) {
                ...AwardImage
            }
            awardsMittelstand: file(relativePath: { eq: "pages/index/awards-2018-mittelstand.jpg" }) {
                ...AwardImage
            }
            awardsChange: file(relativePath: { eq: "pages/index/awards-2017-change-vision.jpg" }) {
                ...AwardImage
            }
            awardsIndustriepreis: file(relativePath: { eq: "pages/index/awards-2018-industriepreis.png" }) {
                ...AwardImage
            }
        }
    `)

    return <div className={Style.wrap}>
        <div className={Style.award}>
            <Img imgStyle={{objectFit: "contain"}} className={Style.image} fixed={awardsGermanInno.childImageSharp.fixed} alt="German Innovation Award 2018 Special" />
        </div>
        <div className={Style.award}>
            <Img imgStyle={{objectFit: "contain"}} className={Style.image} fixed={awardsMittelstand.childImageSharp.fixed} alt="Innovationspreis IT 2018 Bereich E-Learning" />
        </div>
        <div className={Style.award}>
            <Img imgStyle={{objectFit: "contain"}} className={Style.image} fixed={awardsChange.childImageSharp.fixed} alt="Change.Vision Award 2017" />
        </div>
        <div className={Style.award}>
            <Img imgStyle={{objectFit: "contain"}} className={Style.image} fixed={awardsIndustriepreis.childImageSharp.fixed} alt="Industriepreis 2018 Best of" />
        </div>
    </div>
}

export default Awards
