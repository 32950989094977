import React, { Children, ReactNode, useEffect, useMemo, useRef, useState } from "react"

import { graphql } from "gatsby"

import Style from "./Slider.module.css"
import classNames from "../../helpers/classNames"
import { useOnScreen } from "../../helpers/useOnScreen"

function generateSlidesFromChildren(children: ReactNode, id: string | number) {
    return Children.map(children, (child, key) => {
        return <li key={`slider-element-${id}-${key}`} className={Style.slide}>
            {child}
        </li>
    })
}

interface SliderProps {
    defaultCount?: number
}
const Slider: React.FC<SliderProps> = ({ children, defaultCount = 0 }) => {
    const [isRunning, setIsRunning] = useState(false)
    const [wasVisible, setWasVisible] = useState(false)

    const ref = useRef<HTMLUListElement | null>(null)
    const count = useMemo(() => {
        const { current } = ref
        if (!current) {
            return defaultCount
        }

        return Math.ceil(window.innerWidth / current.offsetWidth)
    }, [ref, defaultCount])

    const sliderRef = useRef<HTMLDivElement | null>(null)
    const onScreen = useOnScreen(sliderRef)

    useEffect(() => {
        if (onScreen) {
            setWasVisible(true)
        }
    }, [onScreen])

    useEffect(() => {
        setIsRunning(false)
        const handle = setTimeout(() => {
            setIsRunning(wasVisible)
        }, 200)

        return () => clearTimeout(handle)
    }, [count, wasVisible])

    const clones = useMemo(() => {
        const clones: JSX.Element[] = []

        const realCount = wasVisible ? count + 1 : 1

        for (let i = 0; i < realCount; i++) {
            clones[i] = <ul
                className={classNames({
                    [Style.slider]: true,
                    [Style.animate]: isRunning,
                })}
                key={`slider-${i}`}
                hidden
            >
                {generateSlidesFromChildren(children, i + 1)}
            </ul>
        }

        return clones
    }, [count, children, isRunning, wasVisible])

    return <div ref={sliderRef} className={Style.sliderWrap}>
        {clones}
    </div>
}

export default Slider

export const query = graphql`
    fragment SliderImage on File {
        childImageSharp {
            fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
        }
    }
`
