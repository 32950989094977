import React from "react"

import Style from "./PreHeading.module.css"
import classNames from "../helpers/classNames"
interface PreHeadingProps {
    className?: string
    inline?: boolean
}
const PreHeading: React.FC<PreHeadingProps> = ({ className, inline, children }) => {
    return <h1 className={classNames({
        [Style.heading]: true,
        [className || ""]: true,
        [Style.inline]: inline,
    })}>
        {children}
    </h1>
}

export default PreHeading
