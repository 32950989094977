import { MutableRefObject, useEffect, useState } from "react"

export function useOnScreen(ref: MutableRefObject<Element | null>, options?: IntersectionObserverInit, canUnobserve: boolean = false) {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        if (typeof window !== "undefined" && !window.IntersectionObserver) {
            // fallback for browsers without IntersectionObservers
            setIntersecting(true)
            return
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting || entry.intersectionRatio > 0)
            },
            options
        );

        const { current } = ref

        if (!current) {
            return
        }

        if (canUnobserve) {
            observer.unobserve(current)

            return
        }

        observer.observe(current)

        return () => {
            observer.unobserve(current)
        }
    }, [options, ref, canUnobserve])

    return isIntersecting
}
