import React, { ComponentProps, MouseEventHandler, useCallback } from "react"

import { Button } from "../Button/Button"
import { useDateOverlayContext } from "./DateOverlayContext"

export const DateOverlayButton: React.FC<ComponentProps<typeof Button>> = ({ onClick, ...props }) => {
    const { showOverlay } = useDateOverlayContext()

    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
        showOverlay()
        onClick && onClick(event)
    }, [showOverlay, onClick])

    return <Button {...props} onClick={handleClick} />
}
