const config = {
    desktop: {
        min: "1024px",
    },
    sm_desktop: {
        max: "1239px"
    },
    tablet: {
        max: "1023px",
    },
    tablet_min: {
        min: "768px",
    },
    mobile: {
        max: "767px",
    },
    sm_mobile: {
        max: "430px",
    }
}

const makeBreakpoints = (obj) => {
    const copy = {}

    for (let key in obj) {
        if (!obj.hasOwnProperty(key)) {
            continue;
        }

        const value = obj[key]

        const newVal = []
        if (value.min) {
            newVal.push(`(min-width: ${value.min})`)
        }

        if (value.max) {
            newVal.push(`(max-width: ${value.max})`)
        }

        copy[key] = `${newVal.join(" and ")}`
    }

    return copy
}

module.exports = {
    media: makeBreakpoints(config),
    config,
}
