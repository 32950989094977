import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Slider from "./Slider/Slider"

import RehavistaLogo from "../images/customers/rehavista.svg"
import WasserabiturLogo from "../images/customers/wasserabitur-logo.svg"

const CustomerSlider: React.FC = () => {
    const {
        meridianLogoSlider,
        kinoabiturLogoSlider
    } = useStaticQuery(graphql`
        query CustomerSlider {
            meridianLogoSlider: file(relativePath: { eq: "customers/meridian-spa.png" }) {
                childImageSharp {
                    fixed(width: 200, quality: 90) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            kinoabiturLogoSlider: file(relativePath: { eq: "customers/kinoabitur.png" }) {
                childImageSharp {
                    fixed(width: 200, quality: 90) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `)

    return <Slider defaultCount={3}>
        {
            // @ts-ignore
            <img loading="lazy" width={200} height={46} src={RehavistaLogo} alt="Rehavista" />
        }
        <Img loading="lazy" fixed={meridianLogoSlider.childImageSharp.fixed} alt="Meridian Spa" className="-mt-8" />
        <Img loading="lazy" fixed={kinoabiturLogoSlider.childImageSharp.fixed} alt="Kinoabitur" />
        {
            // @ts-ignore
            <img loading="lazy" width={200} height={46} src={WasserabiturLogo} alt="Wasserabitur" className="-mt-16" />
        }
    </Slider>
}

export default CustomerSlider
