import React, { ReactNode } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import classNames from "../../helpers/classNames"
import { ImageFluid } from "../../helpers/Image"

import { media } from "../../../config/breakpoints"

import Style from "./ImageAndText.module.css"

interface RenderImageAndTextProps {
    image: ReactNode
    contentSide?: {
        desktop?: "left" | "right",
        mobile?: "top" | "bottom",
    }
    fancy?: boolean
    noImageLimit?: boolean
    imageClassName?: string
    cutOff?: boolean
}
export const MediaAndText: React.FC<RenderImageAndTextProps> = ({ image: Image, imageClassName = "", contentSide, fancy, cutOff = false, children, noImageLimit = false }) => {
    return <div className={classNames({
        [Style.outer]: true,
        [Style.cut_off]: cutOff,
        [Style.fancy]: fancy
    })}>
        <div className={classNames({
            [Style.wrap]: true,
            [Style.wrap_reverse_desktop]: !contentSide?.desktop || contentSide?.desktop === "left",
            [Style.wrap_reverse_mobile]: !contentSide?.mobile || contentSide?.mobile === "bottom"
        })}>
            <div aria-hidden={true} hidden className={classNames({
                [Style.image]: true,
                [Style.image_flexible]: noImageLimit,
                [imageClassName]: true,
            })}>
                {Image}
            </div>
            <div className={Style.content}>
                <div className={Style.inner}>
                    {children}
                </div>
            </div>
        </div>
    </div>
}

interface ImageAndTextProps extends Omit<RenderImageAndTextProps, "image"> {
    image: ImageFluid
    tabletImage: ImageFluid
}
const ImageAndText: React.FC<ImageAndTextProps> = ({ image, tabletImage, ...props }) => {
    const sources = [
        tabletImage.childImageSharp.fluid,
        {
            ...image.childImageSharp.fluid,
            media: media.desktop,
        }
    ]

    return <MediaAndText {...props} image={<Img className={Style.fluidImage} fluid={sources} alt="" />} />
}

export default ImageAndText

export const query = graphql`
    fragment ImageAndTextSideImage on File {
        childImageSharp {
            fluid(maxWidth: 660, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    fragment ImageAndTextSideImage_Tablet on File {
        childImageSharp {
            fluid(maxWidth: 768, maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`
